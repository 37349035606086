import { SetupContext } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default function useMessageTemplates({ root }: Partial<SetupContext>) {
  const fetchTemplates = async (type: string) => {
    const axiosInstance = root?.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    let loading = true;
    let loaded = false;
    let templates: any = [];

    await axiosInstance
      .get("message-template", { params: { type: type } })
      .then(({ data: { messageTemplates } }) => {
        templates = messageTemplates;
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          templates = [];
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        loaded = true;
        loading = false;
      });
    return {
      loading,
      loaded,
      templates,
    };
  };
  return {
    fetchTemplates,
  };
}
